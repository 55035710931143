/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import React from "react"
import "firebase/auth"
import 'firebase/database'
import { CardProvider } from "acciondigital-basic-theme/src/components/CartContext"
import AuthProvider from "acciondigital-basic-theme/src/context/AuthContext"
import "bootswatch/dist/sandstone/bootstrap.min.css"
import './src/css/global.css'
import infoSitio from "./src/utils/infoSitio"

const { netlifySiteId: sitio } = infoSitio

// You can delete this file if you're not using it


//alexluong/gatsby-packages
//gatsby-plugin-use-query-params jumps to top of the page when a query param is set #33
//https://github.com/alexluong/gatsby-packages/issues/33
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  // hack to override `shouldUpdateScroll` behavior to bypass useQueryParams in product-checkout
  if (location.search.includes("q=")) {
    return false
  }
  const currentPosition = getSavedScrollPosition(location)
  return currentPosition || [0, 0]
}

export const wrapRootElement = ({ element }) => (
  <AuthProvider sitio={sitio}>
    <CardProvider sitio={sitio} saveToFirebase={true}>
      {element}
    </CardProvider>
  </AuthProvider>
)

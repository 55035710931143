const infoSitio = {
  //   descripcion:
  //     "Tienda de EJEMPLO de productos truper a Mayoreo, con envíos a todo México.",
  //   negocio: "Mi Tienda",
  //   direccion: ["Oaxaca", "México"],
  //   mail: "ventas@cabbur.me",
  telefono: "971 142 4420",
  // netlifySiteId: "f5476a0d-7db8-4a17-845f-f524618975a5",
  netlifySiteId: process.env.GATSBY_NETLIFY_SITE_ID,
}

export default infoSitio

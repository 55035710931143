exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adm-deploys-js": () => import("./../../../src/pages/adm/deploys.js" /* webpackChunkName: "component---src-pages-adm-deploys-js" */),
  "component---src-pages-adm-reviews-js": () => import("./../../../src/pages/adm/reviews.js" /* webpackChunkName: "component---src-pages-adm-reviews-js" */),
  "component---src-pages-adm-test-pages-js": () => import("./../../../src/pages/adm/test-pages.js" /* webpackChunkName: "component---src-pages-adm-test-pages-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mi-cuenta-js": () => import("./../../../src/pages/mi-cuenta.js" /* webpackChunkName: "component---src-pages-mi-cuenta-js" */),
  "component---src-pages-preview-[codigo]-js": () => import("./../../../src/pages/preview/[codigo].js" /* webpackChunkName: "component---src-pages-preview-[codigo]-js" */),
  "component---src-templates-review-template-js": () => import("./../../../src/templates/ReviewTemplate.js" /* webpackChunkName: "component---src-templates-review-template-js" */)
}

